import React, { useRef, useEffect, useState } from "react";
import videojs from "video.js";
import "videojs-plus";
import "videojs-youtube";
import IPlayer from "../../../interfaces/player.interface";
import "videojs-plus/dist/videojs-plus.css";
import ReactPlayer from "react-player";
import Hls from "hls.js";
import "./styles.css";
import { ReplayClient } from "../../../hooks/replayconnector.hook";

const defaultPlayerOptions = {
  autoplay: true,
  muted: true,
  controls: true,
  // aspectRatio: "16:9",
  mobileView: false,
  controlBar: {
    hideOnMouseOut: false,
    subsCapsButton: true, // Button für Untertitel hinzufügen
  },
};

const Index: React.FunctionComponent<any> = (props: any) => {
  const containerRef: any = useRef(null);
  const containerRefPlayer: any = useRef(null);
  const [audioTracks, setAudioTracks] = useState([]);
  const [hlsPlayer, setHls] = useState(null);
  const [isInit, setIsInit] = useState(false);

  // PLAYER CONFIG INITIAL
  useEffect(() => {
    if (
      !isInit &&
      containerRef?.current &&
      props?.sources[0]?.type !== "video/facebook"
    ) {
      const event = props.event;

      const videoEl = containerRef?.current?.querySelector("video");

      const player: any = videojs(videoEl, {
        ...defaultPlayerOptions,
        ...props?.playerOptions,
      });

      player.on("loadedmetadata", () => {
        if (player.seekable().length > 0) {
          const startTime = player.seekable().start(0); // Erster Zeitpunkt im Puffer
          player.currentTime(startTime);
        } else {
          console.error("Kein seekable Bereich verfügbar.");
        }
      });
      

      if (event && event.translations.length > 0) {
        const languageLabels: any = {
          en: "English",
          de: "Deutsch",
          fr: "Français",
          it: "Italiano",
        };

        event.translations.map((lang: any, index: number) => {
          const label = languageLabels[lang] || lang;
          const track = player.addTextTrack("subtitles", label, lang);
          const foundIndex = event.translations.indexOf("de");
          track.mode = index === 0 ? "showing" : "disabled";
        });
      }

      const playerEl: any = player.el();
      const flag: any = player.getChild("PlayToggleLayer").el();
      for (const child of containerRef?.current?.children) {
        if (child !== playerEl) {
          playerEl.insertBefore(child, flag);
        }
      }
      props?.onPlayerInit(player);
      player.src(props?.sources);
      setIsInit(true);

      if (
        containerRefPlayer?.current &&
        props?.sources[0]?.type !== "video/youtube" &&
        props?.sources[0]?.type !== "video/facebook"
      ) {
        const hls: any = new Hls({ enableWorker: false });
        hls.loadSource(props?.sources[0].src);
        hls.attachMedia(containerRefPlayer?.current);
        hls.on(Hls.Events.MANIFEST_PARSED, () => {
          containerRefPlayer?.current?.play();
        });
        hls.on(Hls.Events.AUDIO_TRACKS_UPDATED, (eventName: any, data: any) => {
          if (data.audioTracks.length > 0) {
            setAudioTracks(data.audioTracks);
            props?.onAudioTracksInit &&
              props?.onAudioTracksInit(data.audioTracks);
          }
        });
        setHls(hls);
        props?.onPlayerInit && props?.onPlayerInit(hls);

        return () => {
          props?.onPlayerDispose && props?.onPlayerDispose(null);
        };
      }
    }
  }, [props, containerRefPlayer, isInit]);
  // END PLAYER CONFIG INITIAL

  const replayClientsRef = useRef<Record<string, ReplayClient>>({}); // Speichert die ReplayClient-Instanzen für jede Sprache
  // const currentEndTimeRef = useRef(0); // Speichert die Endzeit global zwischen Untertiteln
  const currentEndTimesRef = useRef<Record<string, number>>({});


  useEffect(() => {

    if (props?.event && Array.isArray(props.event.translations) && props.subtitle && containerRef.current && containerRefPlayer.current) {
      const delay = props.delay || 20000 ;
      const startTime = Date.now() - delay;

      // Erstelle Verbindungen für jede Sprache
      const newClients: Record<string, ReplayClient> = {};
      props.event.translations.forEach((language: string) => {
        const replayClient = new ReplayClient();

        replayClient.connect(
          "wss://prod-replay-controller.madarray.solutions",
          "token-123",
          props.event.id,
          startTime,
          language,
          (lines: any) => {
            console.log("ut + #### START PROCESS ####");
            console.log("ut + New Line");
            const videoEl = containerRef?.current?.querySelector("video");
            const player: any = videojs(videoEl);
            // Initialisierung der Endzeit außerhalb der map-Funktion
            let currentEndTime = player.currentTime(); // Erste Startzeit ist die Playerzeit
            console.log("ut + lines", lines);
            if (lines.length > 1) {
              return;
            }

            // Verarbeitung der Untertitel
            lines.map(
              (
                line: {
                  value: string;
                  meta: { writeStart: number; writeEnd: number };
                },
                index: number
              ) => {
                console.log("ut + Line: " + index);
                console.log("ut + Aktueller currentEndTime:", currentEndTime);

                const metaStartTime = line.meta.writeStart / 1000; // UTC in Sekunden
                const metaEndTime = line.meta.writeEnd / 1000; // UTC in Sekunden

                // Dauer berechnen
                const duration = parseFloat(
                  (metaEndTime - metaStartTime).toFixed(3)
                );
                console.log("ut + Dauer:", duration);

                let streamStartTime = player.currentTime(); // Standard: Playerzeit
                if (
                  currentEndTimesRef.current[language] > 0 &&
                  streamStartTime < currentEndTimesRef.current[language] 
                ) {
                  // Wenn Überschneidung, starte ab der vorherigen Endzeit
                  streamStartTime = currentEndTimesRef.current[language] ;
                }

                // Relative Start- und Endzeit berechnen
                const relativeStartTime = parseFloat(
                  streamStartTime.toFixed(3)
                );
                const relativeEndTime = parseFloat(
                  (relativeStartTime + duration).toFixed(3)
                );
                console.log("ut + relativeStartTime:", relativeStartTime);
                console.log("ut + relativeEndTime:", relativeEndTime);

                console.log(
                  "ut + Relative Zeiten - Start:",
                  relativeStartTime,
                  "Ende:",
                  relativeEndTime
                );

                // Cue erstellen
                const cue = new VTTCue(
                  relativeStartTime,
                  relativeEndTime,
                  line.value
                );

                // Track für die aktuelle Sprache finden
                let track: any = Array.from(player.textTracks()).find(
                  (t: any) => t.language === language
                );

                // Cue hinzufügen
                if (track) {
                  track.addCue(cue);
                } else {
                  console.warn(`Kein Track für Sprache ${language} gefunden.`);
                }

                // Speichere die Endzeit für den nächsten Untertitel
                currentEndTimesRef.current[language] = relativeEndTime + 0.3; // 300 ms Verzögerung

                console.log(
                  "ut + Aktualisierter currentEndTime:",
                  currentEndTime
                );
                console.log("##### end line");
              }
            );

            console.log("ut +  ##### END PROCESS #####");
            console.log("ut + ");
          },
          (ev: any) =>
            console.log(`WebSocket für Sprache ${language} geschlossen:`, ev),
          () => console.log(`Verbindung für Sprache ${language} verloren.`)
        );

        newClients[language] = replayClient;
      });

      // Speichere neue Clients
      replayClientsRef.current = newClients;

      return () => {
        // Disconnecte alle bestehenden Clients
        Object.entries(replayClientsRef.current).forEach(
          ([language, client]) => {
            console.log(`Schließe WebSocket für Sprache ${language}...`);
            client.disconnect();
          }
        );
        replayClientsRef.current = {};
      };
    }
  }, [props.event, containerRef.current, containerRefPlayer.current]);

  return (
    <>
      {props?.sources[0]?.type !== "video/youtube" &&
        props?.sources[0]?.type !== "video/facebook" && (
          <div
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                width: "100%",
                height: "100%",
              }}
              ref={containerRef}
            >
              <video
                id="eventVideo"
                tabIndex={2}
                style={{ width: "100%", height: "100%" }}
                ref={containerRefPlayer}
                controls
                muted
              />
              {props?.children}
            </div>
          </div>
        )}
    </>
  );
};

export default Index;
