import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import { useEvents } from "../../hooks/event.hook";
import Player from "../../components/low-level/iframePlayer";
const PlayerPage = () => {
  const { id } = useParams();

  const { handlers: eventsHandlers, currentEvent } = useEvents();
  const [player, setPlayer] = useState(null);
  const playerOptions = {};
  useEffect(() => {
    if (id) {
      eventsHandlers.getEventData(id);
    }
  }, [id]);
  const [searchParams] = useSearchParams();
  const urlParams = Object.fromEntries([...searchParams]);

  useEffect(() => {
    document.documentElement.style.overflow = "hidden";

    // Clean up the effect to ensure styles are reset
    return () => {
      document.documentElement.style.overflow = "";
    };
  }, []);
  return (
    <>
      {currentEvent && (
        <div style={{ height: "100%", width: "100%" }}>
          <Player
            pageName="publicEvent"
            alwaysController={true}
            onPlayerInit={setPlayer}
            playerOptions={playerOptions}
            onPlayerDispose={setPlayer}
            showPlayer={true}
            sources={currentEvent?.sources}
            event={currentEvent}
            delay={urlParams?.delay ? urlParams.delay : 20000}
            subtitle={true}
          />
        </div>
      )}
    </>
  );
};
export default PlayerPage;
